import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8beb824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__list-images" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_satelital_image_component = _resolveComponent("satelital-image-component")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      "destroy-on-close": "",
      modelValue: _ctx.localVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVisible) = $event)),
      title: _ctx.title,
      ref: "dialog",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_satelital_image_component, { images: _ctx.images }, null, 8, ["images"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}