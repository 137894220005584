import { i18n } from "./../plugins/i18n.plugin";
import moment from "moment-timezone";

/**
 *
 * @param dateString yyyy-dd-mm hh:mm:ss
 * @param timeZone
 * @returns
 */
export const getDate = (dateString: string, timeZone: string): Date => {
  dateString = `${dateString
    .split(" ")[0]
    .replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$3/$2/$1")
    .replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$1/$2/$3")}
    ${dateString.split(" ")[1]}`;

  const value = moment(dateString).tz(timeZone).format("Z"); // +01:00 o +02:00
  const date = `${dateString}${value}`;
  return new Date(date);
};

/**
 * Formatea las diferentes fechas que pueden venir en los archivos de carga manual (Uploader)
 * @param dateString
 * @param timeZone
 * @returns
 */
export const dateFormatFile = (dateString: string, timeZone: string): string | undefined => {
  const date = dateString.split(" ")[0].replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$3-$2-$1");

  const time = dateString.split(" ")[1];

  const dateTime = `${date}T${time}+00:00`;

  const value = moment(dateTime).tz(timeZone).format("Z"); // +01:00 o +02:00

  const dateTimeZone = `${dateTime}`.replace("+00:00", value);

  return moment(dateTimeZone).toISOString();
};

export const dateFormat = (dateString: string, time: string): string => {
  let dateTime = null;
  if (dateString !== "null" && time !== "null") {
    const date = new Date(`${dateString}T${time}Z`);

    dateTime = moment(date).format(i18n.global.t("format.dateTime"));
  }

  return dateTime ? dateTime : "-";
};

export const dateFormatDateTime = (dateString: string): string => {
  let dateTime = null;

  const date = new Date(`${dateString}Z`);

  dateTime = moment(date).format(i18n.global.t("format.dateTime"));

  return dateTime ? dateTime : "-";
};

export const dateFormatDate = (date: Date | undefined): string => {
  let dateTime = null;
  if (date !== undefined) {
    dateTime = moment(date).format(i18n.global.t("format.dateTime"));
  }

  return dateTime === null || dateTime === "Invalid date" ? "-" : dateTime;
};

export const formatDate = (date: Date | undefined) => {
  return date ? date?.toString().replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1") : "";
};

export const formatDateLocale = (date: Date) => {
  if (date instanceof Date) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date
      .toLocaleString(undefined, options)
      .replace(/^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})$/g, "$1/$2/$3 $4:$5:$6");
  }
  return "";
};

export const formatOnlyDateLocale = (date: Date) => {
  if (date instanceof Date) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date
      .toLocaleString(undefined, options)
      .split(", ")[0]
      .replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$1/$2/$3");
  }
  return "-";
};
