import {
  SateliteImage,
  SatelitalSamplingPoint,
  SateliteEstimation,
} from "./../types/satelitalSamplingPoint.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";

export const getSatelital = (): Promise<SatelitalSamplingPoint[]> => {
  return axios.get(`/satelite`).then(responseHandlerDefault);
};

export const getSatelitalById = (samplingPointId: number): Promise<SateliteImage> => {
  return axios
    .get<SateliteImage>(`/satelite/get?id=${samplingPointId}`)
    .then(responseHandlerDefault);
};

export const getParameterData = (
  parameter: string,
  samplingPointId: number,
  from: Date,
  until: Date
): Promise<SateliteEstimation[]> => {
  return axios
    .get<SateliteEstimation[]>(
      `/satelite/history?samplingPoint=${samplingPointId}&parameter=${parameter}&from=${from.toISOString()}&until=${until.toISOString()}`
    )
    .then(responseHandlerDefault);
};

/**
 * get image
 * @param file
 * @param thumbnail
 * @returns
 */
export const getImage = (file: string): Promise<string> => {
  return axios.get(`/satelite/file/${file}`, { responseType: "blob" }).then((res) => {
    return URL.createObjectURL(responseHandlerDefault(res));
  });
};
