
import { getImage } from "@/service/satelital.service";
import { Parameter } from "@/types/parameter.type";
import { SateliteImage } from "@/types/satelitalSamplingPoint.type";
import { dateFormatDate } from "@/utils/date.utils";
import { defineComponent, PropType, ref } from "@vue/runtime-core";

interface ImageData extends SateliteImage {
  title?: string;
}

export default defineComponent({
  components: {},
  props: {
    images: {
      type: Array as PropType<ImageData[]>,
      required: true,
    },
  },
  data() {
    return {
      src: ref<{ [name: string]: string }>({}),
      imageZoom: [] as boolean[],
    };
  },
  setup() {
    return {
      dateFormatDate,
    };
  },
  created() {
    this.loadImage();
  },
  unmounted() {
    // - free memory
    Object.values(this.src).forEach((value) => URL.revokeObjectURL(value));
  },
  methods: {
    loadImage() {
      this.images.forEach((image) => {
        if (!image?.samplingPoint?.id || !image?.fileName || this.src[image.fileName]) {
          return;
        }
        getImage(image.samplingPoint.id + "/" + image.fileName)
          .then((res) => {
            if (!image?.fileName) {
              return;
            }
            if (image && image.fileName) {
              this.src[image.fileName] = res;
            }
          })
          .catch(void 0);
      });
    },
    getNameParameter(parameter: Parameter) {
      let name = "";
      if (parameter.name) {
        if (parameter.name.length > 15) {
          if (parameter.abbreviation) {
            name = parameter.abbreviation;
          } else {
            name = parameter.name.substring(0, 15);
          }
        } else {
          name = parameter.name;
        }
      }
      return name;
    },
    fileName(index: number) {
      return (this.imageZoom[index] ? "zoom-" : "") + this.images[index].fileName;
    },
    toggleZoom(index: number) {
      const image = this.images[index];
      if (!image || !image.samplingPoint) {
        return;
      }

      // - change zoom value
      this.imageZoom[index] = !this.imageZoom[index];
      const zoomFilename = "zoom-" + image.fileName;

      // - load image
      if (this.imageZoom[index] && !this.src[zoomFilename] && image) {
        getImage(image.samplingPoint.id + "/" + zoomFilename)
          .then((res) => {
            this.src[zoomFilename] = res;
          })
          .catch(void 0);
      }
    },
  },
  watch: {
    images: {
      handler() {
        this.loadImage();
      },
      deep: false,
    },
  },
});
