import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ce0e984"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_satelital_list_component = _resolveComponent("satelital-list-component")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_satelital_info_component = _resolveComponent("satelital-info-component")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: 12 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          lg: 6,
          class: "__list"
        }, {
          default: _withCtx(() => [
            (_ctx.satelitalList)
              ? (_openBlock(), _createBlock(_component_satelital_list_component, {
                  key: 0,
                  satelitalSamplingPoints: _ctx.satelitalList,
                  openSatelital: _ctx.changeSatelital,
                  satelitalOpen: _ctx.satelitalOpen
                }, null, 8, ["satelitalSamplingPoints", "openSatelital", "satelitalOpen"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 18,
          class: "__info"
        }, {
          default: _withCtx(() => [
            (_ctx.satelitalOpen)
              ? (_openBlock(), _createBlock(_component_satelital_info_component, {
                  key: 0,
                  satelitalSamplingPointId: _ctx.satelitalOpen
                }, null, 8, ["satelitalSamplingPointId"]))
              : _createCommentVNode("", true),
            (!_ctx.satelitalOpen)
              ? (_openBlock(), _createBlock(_component_el_empty, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}