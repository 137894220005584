
import { defineComponent } from "vue";
import { SateliteImage } from "@/types/satelitalSamplingPoint.type";
import SatelitalImageComponent from "./SatelitalImage.component.vue";

export default defineComponent({
  components: {
    SatelitalImageComponent,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    images: {
      type: Object as () => SateliteImage[],
      required: true,
    },
  },
  data() {
    return {
      localVisible: this.visible,
    };
  },
  watch: {
    localValue: function (newValue) {
      this.$emit(newValue ? "open" : "close");
    },
    visible: function (newValue) {
      this.localVisible = newValue;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
