import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d813b98e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-body" }
const _hoisted_2 = { class: "__zoom" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "image",
  src: "/img/not_found.jpg",
  alt: "Not Image"
}
const _hoisted_5 = {
  key: 2,
  class: "data-image down"
}
const _hoisted_6 = { class: "date-img" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: image?.id || 0,
        class: _normalizeClass(_ctx.images.length === 1 ? 'image-list-one' : 'image-list')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_button, {
              class: "info",
              circle: "",
              onClick: ($event: any) => (_ctx.toggleZoom(index))
            }, {
              default: _withCtx(() => [
                _createElementVNode("em", {
                  class: _normalizeClass('el-icon fas el-icon-search-' + (_ctx.imageZoom[index] ? 'minus' : 'plus'))
                }, null, 2)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          (image && image?.fileName && _ctx.src[_ctx.fileName(index)])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "image",
                src: _ctx.src[_ctx.fileName(index)],
                alt: image.fileName
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (!image?.fileName || !_ctx.src[_ctx.fileName(index)])
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true),
          (image && image?.dateTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.dateFormatDate(image.dateTime)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ]))
}