
import { defineComponent } from "vue";
import SatelitalListComponent from "@/components/satelital/SatelitalList.component.vue";
import SatelitalInfoComponent from "@/components/satelital/SatelitalInfo.component.vue";
import { SatelitalSamplingPoint } from "@/types/satelitalSamplingPoint.type";
import { getSatelital } from "@/service/satelital.service";

export default defineComponent({
  components: { SatelitalListComponent, SatelitalInfoComponent },
  data() {
    return {
      satelitalSamplingPointId: undefined as number | undefined,
      samplingPointId: undefined as number | undefined,
      satelitalList: [] as SatelitalSamplingPoint[],
      satelitalOpen: -1,
    };
  },
  // created() {
  //   // - get satelitalSamplingPoint id from route
  //   if (this.$route.query.satelitalSamplingPoint) {
  //     this.satelitalSamplingPointId = Number(this.$route.query.satelitalSamplingPoint);
  //   } else {
  //     this.satelitalSamplingPointId = undefined;
  //   }
  // },
  async mounted() {
    await this.getSatelital();

    if (!this.satelitalSamplingPointId && this.satelitalList.length > 0) {
      this.satelitalSamplingPointId = this.satelitalList[0].id;
      this.samplingPointId = this.satelitalList[0].samplingPoint?.id;
    }

    // - check initial satelitalSamplingPoint id
    if (this.satelitalSamplingPointId && this.samplingPointId) {
      this.changeSatelital(this.satelitalSamplingPointId, this.samplingPointId);
    }
  },
  methods: {
    async getSatelital() {
      this.satelitalList = await getSatelital();
    },
    changeSatelital(satelitalToOpen: number, samplingPointToOpen: number) {
      this.satelitalOpen = satelitalToOpen;

      // Reemplaza o añade el valor de samplingPoint en la ruta
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          samplingPoint: samplingPointToOpen,
        },
      });
    },
  },
});
