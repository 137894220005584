
import { defineComponent } from "vue";
import { dateFormatDate } from "@/utils/date.utils";
import { SatelitalSamplingPoint } from "@/types/satelitalSamplingPoint.type";

export default defineComponent({
  props: {
    satelitalSamplingPoints: { type: Object, required: true, immediate: true, deep: true },
    openSatelital: { type: Function, required: true },
    satelitalOpen: { type: Number },
  },
  watch: {
    satelitalSamplingPoints: function (satelitalSamplingPoints) {
      this.satelitalList = satelitalSamplingPoints as unknown as SatelitalSamplingPoint[];
    },
  },
  data() {
    return {
      satelitalList: undefined as SatelitalSamplingPoint[] | undefined,
    };
  },
  mounted() {
    // this.getSatelital();
  },
  methods: {
    changeSatelital(satelitalToOpen: SatelitalSamplingPoint) {
      this.openSatelital(satelitalToOpen.id, satelitalToOpen.samplingPoint?.id);
    },
    formatDate(date: Date) {
      return dateFormatDate(date);
    },
  },
});
