
import { defineComponent, toRaw } from "vue";
import { LineChart } from "vue-chart-3";
import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  ChartOptions,
  registerables,
  TooltipItem,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { SateliteEstimation, SateliteImage } from "@/types/satelitalSamplingPoint.type";
import { dateFormatDate } from "@/utils/date.utils";
import { getParameterData, getSatelitalById } from "@/service/satelital.service";
import SatelitalImageComponent from "./SatelitalImage.component.vue";
import SatelitalImageModalComponent from "./SatelitalImageModal.component.vue";
import { i18n } from "@/plugins/i18n.plugin";

Chart.register(...registerables);
Chart.register(zoomPlugin);

export default defineComponent({
  components: {
    LineChart,
    SatelitalImageComponent,
    SatelitalImageModalComponent,
  },
  props: {
    satelitalSamplingPointId: { type: Number, required: true, immediate: true, deep: true },
  },
  setup() {
    return { dateFormatDate };
  },
  watch: {
    satelitalSamplingPointId: async function (satelitalSamplingPointsId: number) {
      if (satelitalSamplingPointsId >= 0) {
        this.satelitalPoint = await getSatelitalById(satelitalSamplingPointsId);
        this.parameterGraph =
          this.satelitalPoint.estimations && this.satelitalPoint.estimations[0].parameter
            ? this.satelitalPoint.estimations[0].parameter
            : "";
        this.fillData();
      } else {
        this.satelitalPoint = undefined;
      }
    },
    parameterGraph: async function (newValue: string) {
      this.parameterGraph = newValue;
      this.fillData();
    },
  },
  mounted() {
    const actualDate = new Date();
    this.startDate = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth() - 1,
      actualDate.getDate(),
      actualDate.getHours(),
      actualDate.getMinutes(),
      actualDate.getSeconds()
    );
    this.endDate = actualDate;
  },
  data() {
    return {
      satelitalPoint: undefined as SateliteImage | undefined,
      parameterGraph: "" as string,
      startDate: new Date(),
      endDate: new Date(),
      parameterHistory: [] as SateliteEstimation[],
      dataCollection: undefined as ChartData<"line"> | undefined,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              scaleMode: "x",
            },
            limits: {},
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
          },
          tooltip: {
            callbacks: {
              label: (t: TooltipItem<"line">): string => {
                if (t.datasetIndex === 0) {
                  return "Imagen inválida";
                } else if (t.datasetIndex === 1) {
                  return i18n.global.n(Number(t.raw), "fixed");
                }
                return "";
              },
            },
          },
        },
        onClick: this.clickChartPoint,
      } as ChartOptions<"line">,
      hasData: false,
      showDate: false,
      showRangeDate: false,
      dataMin: undefined as number | undefined,
      dataMax: undefined as number | undefined,
      dataAverage: 0 as number,
      dialogImage: undefined as SateliteImage | undefined,
      dialogImageTitle: "" as string,
      dialogImageVisible: false,
      dialogInfoParamVisible: false,
      dialogInfoParam: "" as string,
    };
  },
  methods: {
    changeParameterGraph(parameter: string) {
      this.parameterGraph = parameter;
    },
    async fillData() {
      if (
        !this.satelitalPoint ||
        !this.satelitalPoint.samplingPoint ||
        !this.satelitalPoint.samplingPoint.id
      ) {
        return;
      }
      this.parameterHistory = (
        await getParameterData(
          this.parameterGraph,
          this.satelitalPoint?.samplingPoint?.id,
          this.startDate,
          this.endDate
        )
      ).reverse();
      if (!this.parameterHistory || this.parameterHistory.length <= 0) {
        this.hasData = false;
        return;
      } else {
        this.hasData = true;
      }

      const values: (number | null)[] = [];
      const valuesNotNull: number[] = [];
      const valuesNull: (number | null)[] = [];
      this.parameterHistory.forEach((param) => {
        if (param.value === null || param.value === undefined) {
          values.push(null);
          valuesNull.push(0);
        } else {
          values.push(Number(param.value));
          valuesNotNull.push(Number(param.value));
          valuesNull.push(null);
        }
      });
      if (valuesNotNull.length > 0) {
        const sum = +valuesNotNull.reduce((previous, current) => (current += previous));
        this.dataAverage = +(sum / valuesNotNull.length).toFixed(2);

        this.dataMax = +valuesNotNull.reduce((value, newValue) =>
          value > newValue ? value : newValue
        );
        this.dataMin = valuesNotNull.reduce((value, newValue) =>
          value < newValue ? value : newValue
        );
      }
      const colors = values.map((_) => {
        if (_ === this.dataMax) {
          return "#ffc700"; // yellow
        } else if (_ === this.dataMin) {
          return "#019245"; // green
        }
        return "#4D9DE0";
      });

      const averages = this.parameterHistory.map(() => this.dataAverage);

      this.dataCollection = {
        labels: this.parameterHistory.map((_) =>
          _.image?.dateTime ? dateFormatDate(_.image.dateTime) : ""
        ),
        datasets: [
          {
            label: this.parameterHistory[0].parameter,
            data: valuesNull,
            borderColor: "gray",
            backgroundColor: "gray",
            borderWidth: 2,
            borderDash: [10, 5],
            pointRadius: 4,
            showLine: false,
          },
          {
            label: this.parameterHistory[0].parameter,
            pointBackgroundColor: colors,
            pointBorderColor: colors,
            pointHoverBackgroundColor: colors,
            pointHoverBorderColor: colors,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            data: values,
            borderColor: "#4D9DE0", // line
            borderWidth: 1, // size line
            pointRadius: 4,
            fill: {
              target: true,
              above: "rgba(77, 157, 224, 0.5)",
            },
          },
          {
            label: "average",
            data: averages,
            pointBackgroundColor: colors,
            borderColor: "green", // line
            cubicInterpolationMode: "monotone",
            borderWidth: 2,
            borderDash: [10, 5],
            pointRadius: 0, // TO NOT SHOW THE POINTS
          },
        ],
      };
    },
    showLastMonth() {
      this.removeBlueClass();
      this.addBlueClass("btnMonth");
      const actualDate = new Date();
      this.startDate = new Date(
        actualDate.getFullYear(),
        actualDate.getMonth() - 1,
        actualDate.getDate(),
        actualDate.getHours(),
        actualDate.getMinutes(),
        actualDate.getSeconds()
      );
      this.endDate = actualDate;
      this.fillData();
    },
    showLastTrimester() {
      this.removeBlueClass();
      this.addBlueClass("btnTri");
      const actualDate = new Date();
      this.startDate = new Date(
        actualDate.getFullYear(),
        actualDate.getMonth() - 3,
        actualDate.getDate(),
        actualDate.getHours(),
        actualDate.getMinutes(),
        actualDate.getSeconds()
      );
      this.endDate = actualDate;
      this.fillData();
    },
    showLastSemester() {
      this.removeBlueClass();
      this.addBlueClass("btnSem");
      const actualDate = new Date();
      this.startDate = new Date(
        actualDate.getFullYear(),
        actualDate.getMonth() - 6,
        actualDate.getDate(),
        actualDate.getHours(),
        actualDate.getMinutes(),
        actualDate.getSeconds()
      );
      this.endDate = actualDate;
      this.fillData();
    },
    showLastYear() {
      this.removeBlueClass();
      this.addBlueClass("btnYear");
      const actualDate = new Date();
      this.startDate = new Date(
        actualDate.getFullYear() - 1,
        actualDate.getMonth(),
        actualDate.getDate(),
        actualDate.getHours(),
        actualDate.getMinutes(),
        actualDate.getSeconds()
      );
      this.endDate = actualDate;
      this.fillData();
    },
    removeBlueClass() {
      const blueBtn = document.getElementsByClassName("blue");
      if (blueBtn[0]) {
        blueBtn[0].className = blueBtn[0].className.replace("blue", "");
      }
    },
    addBlueClass(idBtn: string) {
      const btnDate = document.getElementById(idBtn);
      btnDate?.classList.add("blue");
    },
    clickChartPoint(event: ChartEvent, elements: ActiveElement[], chart: Chart) {
      if (elements.length === 0) {
        return;
      }

      // - get image and tile by chart point index
      const index = elements[0].index;
      const parameterHistory = toRaw(this.parameterHistory);
      if (parameterHistory[index].image !== undefined) {
        this.dialogImage = parameterHistory[index].image;
        this.dialogImageVisible = true;
        this.dialogImageTitle =
          this.satelitalPoint?.samplingPoint?.samplingPoint?.name + " " + chart.tooltip?.title[0] ||
          "";
      } else {
        this.dialogImageClose();
      }
    },
    dialogImageClose() {
      this.dialogImage = undefined;
      this.dialogImageTitle = "";
      this.dialogImageVisible = false;
    },
    dialogInfoParamOpen(param: string) {
      (this.dialogInfoParamVisible = true), (this.dialogInfoParam = param);
    },
  },
});
